import { isEditMode } from "../../../src/js/utils";
export default class MapLocations {
    constructor(rootNode, props) {
        if (isEditMode()) return;

        this.root = rootNode;

        this.activeItem = 0;

        this.paddingBottom = 16;
        this.items = this.root.querySelectorAll(".accordion-item");

        this.map = this.root.querySelector(".map");
        this.pointer = this.root.querySelector(".pointer");

        this.openItem(0);
        this.initAccordion();
    }

    initAccordion() {
        this.items.forEach((item, i) => {
            const button = item.querySelector(".accordion-button");

            button.addEventListener("click", () => {
                if (this.activeItem == i) return;
                this.closeItem(this.activeItem);
                this.activeItem = i;
                this.openItem(this.activeItem);
            });
        });
    }

    openItem(i) {
        if(!this.items[i]) return;

        const content = this.items[i].querySelector(".accordion-content");

        if(!content) return;

        content.style.setProperty(
            "height",
            `${content.scrollHeight + this.paddingBottom}px`
        );

        const button = this.items[i].querySelector(".accordion-button");
        button.classList.add("active");

        const countries = this.items[i].getAttribute("data-targets");
        const targets = countries.split(", ");
        targets.forEach((target) => {
            this.map.querySelector(`#${target}`).classList.add("active");
        });

        const left = this.items[i].getAttribute("data-position-x");
        this.pointer.style.left = `${left}%`;

        const bottom = this.items[i].getAttribute("data-position-y");
        this.pointer.style.bottom = `${bottom}%`;
    }

    closeItem(i) {
        if(!this.items[i]) return;

        const content = this.items[i].querySelector(".accordion-content");

        if(!content) return;

        content.style.setProperty("height", "0px");

        const button = this.items[i].querySelector(".accordion-button");
        button.classList.remove("active");

        const countries = this.items[i].getAttribute("data-targets");
        const targets = countries.split(", ");
        targets.forEach((target) => {
            this.map.querySelector(`#${target}`).classList.remove("active");
        });
    }
}
