export default class Header {
    constructor(rootNode, props) {
        this.root = rootNode;

        this.nav = this.root.querySelector("nav");

        this.initClickListener();
    }

    initClickListener() {
        const toggleMenu = this.root.querySelector("#toggle-menu");

        toggleMenu.addEventListener("click", () => {
            // set max-height of nav in order to be scrollable at small screen heights

            this.nav.classList.toggle("open");
            toggleMenu.classList.toggle("open");
        });
    }
}
